/* ==============================
   🚀 FLEX UTILITY CLASSES
   ============================== */

/* 📌 Display Flex */
.flex { display: flex; }
.inline-flex { display: inline-flex; }

/* 📌 Flex Direction */
.flex-row { flex-direction: row; }
.flex-row-reverse { flex-direction: row-reverse; }
.flex-col { flex-direction: column; }
.flex-col-reverse { flex-direction: column-reverse; }

/* 📌 Justify Content */
.justify-start { justify-content: flex-start; }
.justify-end { justify-content: flex-end; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.justify-evenly { justify-content: space-evenly; }

/* 📌 Align Items */
.items-start { align-items: flex-start; }
.items-end { align-items: flex-end; }
.items-center { align-items: center; }
.items-baseline { align-items: baseline; }
.items-stretch { align-items: stretch; }

/* 📌 Align Self */
.self-auto { align-self: auto; }
.self-start { align-self: flex-start; }
.self-end { align-self: flex-end; }
.self-center { align-self: center; }
.self-baseline { align-self: baseline; }
.self-stretch { align-self: stretch; }

/* 📌 Align Content */
.content-start { align-content: flex-start; }
.content-end { align-content: flex-end; }
.content-center { align-content: center; }
.content-between { align-content: space-between; }
.content-around { align-content: space-around; }
.content-evenly { align-content: space-evenly; }

/* 📌 Flex Wrap */
.flex-wrap { flex-wrap: wrap; }
.flex-nowrap { flex-wrap: nowrap; }
.flex-wrap-reverse { flex-wrap: wrap-reverse; }

/* 📌 Flex Grow & Shrink */
.flex-grow { flex-grow: 1; }
.flex-grow-0 { flex-grow: 0; }
.flex-shrink { flex-shrink: 1; }
.flex-shrink-0 { flex-shrink: 0; }

/* 📌 Flex Basis */
.basis-auto { flex-basis: auto; }
.basis-0 { flex-basis: 0%; }
.basis-1-4 { flex-basis: 25%; }
.basis-1-2 { flex-basis: 50%; }
.basis-3-4 { flex-basis: 75%; }
.basis-full { flex-basis: 100%; }

/* 📌 Order */
.order-first { order: -1; }
.order-last { order: 999; }
.order-none { order: 0; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }

/* 📌 Advanced Flexbox Classes */
.flex-center { display: flex; justify-content: center; align-items: center; }
.flex-col-center { display: flex; flex-direction: column; justify-content: center; align-items: center; }
.flex-between { display: flex; justify-content: space-between; align-items: center; }
.flex-around { display: flex; justify-content: space-around; align-items: center; }
.flex-evenly { display: flex; justify-content: space-evenly; align-items: center; }
.flex-full-height { display: flex; height: 100vh; justify-content: center; align-items: center; }

/* 📌 Responsive Flex Classes */
@media (max-width: 600px) {
  .sm-flex-col { flex-direction: column; }
  .sm-justify-center { justify-content: center; }
  .sm-items-center { align-items: center; }
}

@media (min-width: 768px) {
  .md-flex-row { flex-direction: row; }
  .md-justify-between { justify-content: space-between; }
  .md-items-start { align-items: flex-start; }
}

@media (min-width: 1024px) {
  .lg-flex-wrap { flex-wrap: wrap; }
  .lg-justify-around { justify-content: space-around; }
  .lg-items-center { align-items: center; }
}

@media (min-width: 1280px) {
  .xl-flex-nowrap { flex-wrap: nowrap; }
  .xl-justify-end { justify-content: flex-end; }
  .xl-items-end { align-items: flex-end; }
}

/* 📌 Flex Grid Layouts */
.flex-grid-2 { display: flex; flex-wrap: wrap; gap: 10px; }
.flex-grid-2 > * { flex: 1 1 calc(50% - 10px); }
.flex-grid-3 { display: flex; flex-wrap: wrap; gap: 10px; }
.flex-grid-3 > * { flex: 1 1 calc(33.333% - 10px); }

/* 📌 Sticky Footer Layout */
.flex-sticky-footer { display: flex; flex-direction: column; min-height: 100vh; }
.flex-sticky-footer > .content { flex: 1; }
